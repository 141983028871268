import React , {useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Container, Card, Form, Button, ListGroup, OverlayTrigger, Popover, Tooltip, Carousel, InputGroup, FormControl, Table, Badge } from 'react-bootstrap'
import Meta from '../components/Meta'

import {loadCourses} from '../actions/courseActions'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect
  } from "react-device-detect";




export const LandingScreen = ({match, history}) => {

    
    const dispatch = useDispatch()



    const courselist = useSelector(state => state.courselist)
    const {courses} = courselist

    const [sectionType, setSectionType] = useState("all") 
    const [newModel, setNewModel] = useState("") 


    const selectPageRequest = (module_in) => {
        setSectionType(module_in)
        //dispatch(loadLecturesAdmin(module_in))
    }

    const navigateToPage = (pageURL) => {
        history.push(`/lectures/${pageURL}`)
    }
    






    useEffect(() => {
        dispatch(loadCourses())

    }, [])



    return(



        <div style={{marginTop:'0px'}}>


            <Meta title={'Pixagan.AI'} description={'Teaching you the fundamentals of Deep Learning and other Artifical Intelligence (AI) topics. We teach you the basic math topics that you will need to understand AI as well as the required Python coding. '}/>


            <section style={{backgroundColor:'#f0f2f2', marginLeft:'0px', marginRight:'0px', marginTop:'0px', padding:'20px'}}>

                <p className='h1'>AI and Machine Learning tech is growing at breakneck speed</p>
                {/* <p className='h1'>Your AI-Edu Station</p> */}
                <p className='h3'>Learn and stay up-to-date at</p>
               
               {/* <p className='h1'>Pixagan.AI</p> */}

               {isMobile ? (
                <img src="pixagan.png" alt="Pixagan Logo" style={{width:'20vw'}}/>
                ) : (
                <img src="pixagan.png" alt="Pixagan Logo" style={{width:'20vw'}}/>
                )}



               <p className='h3'>Courses on Deep Learning in English and Hindi</p>
            <Link to='courses/deep-learning-from-scratch'>
                <Button style={{borderRadius:'20px'}}>Start Learning</Button>

            </Link>

            </section>


        


            <section>

            
            <ListGroup horizontal>
                <ListGroup.Item style={{borderTop:'None', borderBottom:'None', borderLeft:'None'}}>
                    <p className='h4' style={{textAlign:'center', color:'#6b0752'}}>Courses</p>
                </ListGroup.Item>
                {/* <ListGroup.Item onClick={()=>selectPageRequest("mlbasics")} style={{backgroundColor:sectionType=='mlbasics'?'black':'white', color:sectionType=='mlbasics'?'white':'black'}}>
                    <p className='h5'>Machine Learning Basics</p>
                </ListGroup.Item> */}
                <ListGroup.Item onClick={()=>selectPageRequest("basics")} style={{backgroundColor:sectionType=='all'?'black':'white', color:sectionType=='all'?'white':'black'}}>
                    <p className='h5'>All</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("basics")} style={{backgroundColor:sectionType=='basics'?'black':'white', color:sectionType=='basics'?'white':'black'}}>
                    <p className='h5'>Deep Learning</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("python")} style={{backgroundColor:sectionType=='python'?'black':'white', color:sectionType=='python'?'white':'black'}}>
                    <p className='h5'>Coding/Python</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("maths")} style={{backgroundColor:sectionType=='maths'?'black':'white', color:sectionType=='maths'?'white':'black'}}>
                    <p className='h5'>Math for AI</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("genai")} style={{backgroundColor:sectionType=='genai'?'black':'white', color:sectionType=='genai'?'white':'black'}}>
                    <p className='h5'>Gen AI</p>
                </ListGroup.Item>
                {/* <ListGroup.Item onClick={()=>selectPageRequest("buildai")} style={{backgroundColor:sectionType=='buildai'?'black':'white', color:sectionType=='buildai'?'white':'black'}}>
                    <p className='h5'>Building AI Apps</p>
                </ListGroup.Item>
                <ListGroup.Item onClick={()=>selectPageRequest("useai")} style={{backgroundColor:sectionType=='useai'?'black':'white', color:sectionType=='useai'?'white':'black'}}>
                    <p className='h5'>Using AI Tools</p>
                </ListGroup.Item> */}
            </ListGroup>


        
            <ListGroup style={{border:'None'}}>
            {courses && courses.map((course, index)=>(
                <ListGroup.Item style={{padding:'0px', border:'None', marginBottom:'20px'}}>
                    <Link to={`/courses/${course.url}`}>
                    <Card style={{padding:'10px', borderRadius:'20px'}}>
                        
                        <Card.Header>
                             <p>
                                <span className='h4'>{course.title}</span>
                                {course.isPosted == false && (
                                    <Badge style={{backgroundColor:'#6b0752', color:'white', padding:'10px', borderRadius:'10px', marginLeft:'10px'}}>Coming Soon</Badge>
                                )}
                                
                            </p>   
                        </Card.Header>
                        <Card.Img>

                        </Card.Img>
                        <Card.Body>
                            <Row>
                                <Col>
                                {isMobile ? (
                                <img src={course.cover} alt={course.title} style={{width:'30vw'}}/>
                                ) : (
                                <img src={course.cover} alt={course.title} style={{width:'30vw'}}/>
                                )}
                                </Col>
                                <Col style={{minWidth:'60vw'}}>
                                    <p>Instructor : {course.instructors}</p>
                                    <p>Description : {course.description}</p>
                                    
                                </Col>
                            </Row>
                
                          
                        </Card.Body>

                        

                
                    </Card>
                    </Link>
                    
                </ListGroup.Item>
            ))}
            </ListGroup>
            

    

            </section>




            <section style={{backgroundColor:'#f0f2f2'}}>

                
                <p className='h1'>Follow the story from its origins and stay up to date with the latest innovations with Storyline.</p>

              

                <Link to='/storyline'>
                    <Button style={{borderRadius:'20px'}}>Storyline</Button>
                
                </Link>

            </section>

            {/* <section>

                <p className='h1'>Learning is an interactive and visual process that should involve experimentation.</p>
                <p className='h5'>Pixagan Apps are small tools that give you a no-code UI to experiment with different ideas and algorithms.</p>

             
                <Link to='/apps'>
                    <Button style={{borderRadius:'20px'}}>Apps</Button>
                
                </Link>

            </section> */}


            <section>
            {/* <section style={{backgroundColor:'#f0f2f2'}}></section> */}

                <p className='h1'>About Us</p>
                <p className='h5'>
                    Pixagan.AI has been created by Dr Anil Variyar with the objective of making it easy for everyone to understand and access the basics of AI, and more specifically, Deep Learning
                </p>
                
                <p className='h5'>
                    Anil has a PhD in Aeronautics and Astronautics from Stanford University. He has worked on Machine Learning and Deep Learning as part of his research and work over the years.
                </p>


            </section>


        </div>



    )


}

export default LandingScreen


